.images-container{
  display: flex;
  overflow-x: scroll;
  justify-content: center;
}

.image-container {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
}

.image-item {
  width: 130px;
  height: 130px;
  object-fit: contain;
  transition: 0.3s;
}

.full {
  width: 100%;
}
.image-capturer {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  height:auto;
}

.reactEasyCrop_CropArea {
  z-index: 500;
  box-shadow: none !important;
}

.canvas-controller {
  position: absolute;
  cursor: move;
  z-index: 500;
  min-width:600px;
  min-height:600px;
  width:100%;
  height:100%;
  max-width:600px;
  max-height:auto;
  top: 0;
  left: 0;
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  
}

.image-container > .selected {
  width: 150px;
  height: 150px;
  border: 1px solid blue;
}
.processed-image-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.scrolltest::-webkit-scrollbar{
  background-color:#D9B482;
}


.processed-image-base {
  border: 1px solid #3e3a39;
  position: relative;
  width:100%;
  height:auto;
}
#result-canvas.ColorPickImage{
  min-width:450px;
  width:100%;
  height:100%;
}

.image-base {
  width: 100%;
  height: 100%;
}
.processed-image-lace {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.processed-image-curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.space-evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.processed-image {
  width: 100%;
  height: 100%;
}

.btn {
  padding: 12px 8px;
  border: 1px solid #fcfcfc;
}

.btn-active {
  background-color: lightskyblue;
  color: white;
}

.btn-active:focus {
  background-color: lightskyblue !important;
  color: white !important;
}

.btn-active:hover {
  background-color: lightskyblue !important;
  color: white !important;
}

.ant-slider-rail {
  background-color: #bfbfbf;
}

#myCanvas {
  cursor: move;
}
.processed-component-container {
  display: flex;
  justify-content: center;
}
.component-controller {
  display: block;
  position: absolute;
  cursor: move;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  max-width: 200%;
  max-height: 200%;
}

.component-controller * {
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  -webkit-user-drag: none;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  height: 3px;
  width: 100%;
  background: #ffffff;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #404040;
  border: solid 1px #ffffff;
  width: 10px;
  height: 20px;
}
input[type="range"]::-moz-range-thumb {
  background: #404040;
  border: solid 1px #ffffff;
  width: 10px;
  height: 30px;
}
input[type="range"]::-moz-focus-outer {
  border: 0;
}
input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.3);
}

