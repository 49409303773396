/* html {
  overflow-x: scroll;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgb(247, 247, 247);
  
	background-image:
	 linear-gradient(-90deg, #fff 50%, transparent 50%);
	background-size: 120px 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  background-color: white;
  border-radius: 30px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 30px;
}
::-webkit-scrollbar-thumb {
  background-color: #cce9dd;
  border-radius: 30px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}

.clothImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.clothImg:hover {
  width: 100%;
  height: 100%;
}

/* スクロールボタン */
.radio-input {
  display: none;
  top: 0px;
}
.radio-input + label {
  content: "";
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  /* height: 100%; */
  border: 1px solid lightgrey;
  padding: 3px;
  border-radius: 10px;
  margin: 0px;
}
.radio-input:checked + label {
  content: "";
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  /* height: 100%; */
  border: 3px solid #046B5E;
  border-radius: 10px;
  padding: 3px;
  margin: 0px;
}
.CGspace.col-12.col-md-8 {
  position: -webkit-sticky; 
  position: sticky;
  top: 30px;
  height: 0px;
  z-index: 100;
}
.RoomBase {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
.PointerBase {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.Funiture {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
}
.Rail {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
}
.Lace {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
.Drape {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
.Roll {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
.Shade {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
.wallpaper {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
.Wallpaper {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
button{
  width:100%;
}
.dropdown-toggle .btn .btn-success{
  width:100%;
}
.dropdown-menu .show{
  width:100%;
}
.dropdown-menu{
  width:100%
}
@media only screen and (max-width: 767px) {
  
}
@media only screen and (max-width: 767px) {
  .Choices.col-12.col-md-4 {
  /* top: 350px; */
  }
}

/* カーソルが重なった時指マークにする */
.container.w-100.mx-0.px-0.topmenu {
  cursor: pointer;
  cursor: hand;
}
.col-6.my-3.py-3.lineup {
  cursor: pointer;
  cursor: hand;
}


/* DropDown */
  /* Room */
  .box_Room label{
    display: block;
    cursor: pointer;
  }
  .box_Room input[type="checkbox"].on-off_Room,
  .box_Room input[type="checkbox"].on-off_Room +div{
    display: none;
  }
  .box_Room input[type="checkbox"].on-off_Room:checked +div{
    display: block;
  }
  /* CurtainRail */
  .box_Rail label{
    display: block;
    cursor: pointer;
  }
  .box_Rail input[type="checkbox"].on-off_Rail,
  .box_Rail input[type="checkbox"].on-off_Rail +div{
    display: none;
  }
  .box_Rail input[type="checkbox"].on-off_Rail:checked +div{
    display: block;
  }
  /* CurtainDOC */
  /* DOC=> DrapeOpenClose */
  .box_DOC label{
    display: block;
    cursor: pointer;
  }
  .box_DOC input[type="checkbox"].on-off_DOC,
  .box_DOC input[type="checkbox"].on-off_DOC +div{
    display: none;
  }
  .box_DOC input[type="checkbox"].on-off_DOC:checked +div{
    display: block;
  }
  /* Curtain_ColorPicker */

  .box_ColorPicker label{
    display: block;
    cursor: pointer;
  }
  .box_ColorPicker input[type="checkbox"].on-off_ColorPicker,
  .box_ColorPicker input[type="checkbox"].on-off_ColorPicker +div{
    display: none;
  }
  .box_ColorPicker input[type="checkbox"].on-off_ColorPicker:checked +div{
    display: block;
  }
  /* Curtain_Taste */
  .box_Taste label{
    display: block;
    cursor: pointer;
  }
  .box_Taste input[type="checkbox"].on-off_Taste,
  .box_Taste input[type="checkbox"].on-off_Taste +div{
    display: none;
  }
  .box_Taste input[type="checkbox"].on-off_Taste:checked +div{
    display: block;
  }
  /* Curtain_Functions */
  .box_func label{
    /* display: block; */
    cursor: pointer;
  }
  .box_func input[type="checkbox"].on-off_func,
  .box_func input[type="checkbox"].on-off_func +div{
    display: none;
  }
  .box_func input[type="checkbox"].on-off_func:checked +div{
    display: block;
  }
  /* Curtain_BPN */
  /* BPN =>Brand_Price_Number */
  .box_BPN label{
    display: block;
    cursor: pointer;
  }
  .box_BPN input[type="checkbox"].on-off_BPN,
  .box_BPN input[type="checkbox"].on-off_BPN +div{
    display: none;
  }
  .box_BPN input[type="checkbox"].on-off_BPN:checked +div{
    display: block;
  }
  /* Curtain_Result */
  .box_result label{
    display: block;
    cursor: pointer;
  }
  .box_result input[type="checkbox"].on-off_result,
  .box_result input[type="checkbox"].on-off_result +div{
    display: none;
  }
  .box_result input[type="checkbox"].on-off_result:checked +div{
    display: block;
  }

  /* Lace */
  /* LFunc=>LaceFunctions */
  .box_LFunc label{
    /* display: block; */
    cursor: pointer;
  }
  .box_LFunc input[type="checkbox"].on-off_LFunc,
  .box_LFunc input[type="checkbox"].on-off_LFunc +div{
    display: none;
  }
  .box_LFunc input[type="checkbox"].on-off_LFunc:checked +div{
    display: block;
  }
  /* Lace_BPN */
  /* LBPN =>Lace_Brand_Price_Number */
  .box_LBPN label{
    display: block;
    cursor: pointer;
  }
  .box_LBPN input[type="checkbox"].on-off_LBPN,
  .box_LBPN input[type="checkbox"].on-off_LBPN +div{
    display: none;
  }
  .box_LBPN input[type="checkbox"].on-off_LBPN:checked +div{
    display: block;
  }

  /* Curtain_Result */
  .box_Lresult label{
    display: block;
    cursor: pointer;
  }
  .box_Lresult input[type="checkbox"].on-off_Lresult,
  .box_Lresult input[type="checkbox"].on-off_Lresult +div{
    display: none;
  }
  .box_Lresult input[type="checkbox"].on-off_Lresult:checked +div{
    display: block;
  }
  /* CurtainDOC */
  /* DOC=> DrapeOpenClose */
  .box_LOC label{
    display: block;
    cursor: pointer;
  }
  .box_LOC input[type="checkbox"].on-off_LOC,
  .box_LOC input[type="checkbox"].on-off_LOC +div{
    display: none;
  }
  .box_LOC input[type="checkbox"].on-off_LOC:checked +div{
    display: block;
  }
  /* CurtainConstruction */
  .box_Construction label{
    display: block;
    cursor: pointer;
  }
  .box_Construction input[type="checkbox"].on-off_Construction,
  .box_Construction input[type="checkbox"].on-off_Construction +div{
    display: none;
  }
  .box_Construction input[type="checkbox"].on-off_Construction:checked +div{
    display: block;
  }
  /* ConstWindow */
/* CurtainConstruction */
.box_ConstWindow label{
  display: block;
  cursor: pointer;
}
.box_ConstWindow input[type="checkbox"].on-off_ConstWindow,
.box_ConstWindow input[type="checkbox"].on-off_ConstWindow +div{
  display: none;
}
.box_ConstWindow input[type="checkbox"].on-off_ConstWindow:checked +div{
  display: block;
}
  /* Photo_Window */
.box_Window label{
  display: block;
  cursor: pointer;
}
.box_Window input[type="checkbox"].on-off_Window,
.box_Window input[type="checkbox"].on-off_Window +div{
  display: none;
}
.box_Window input[type="checkbox"].on-off_Window:checked +div{
  display: block;
}
/*  */
.box_BN label{
  display: block;
  cursor: pointer;
}
.box_BN input[type="checkbox"].on-off_BN,
.box_BN input[type="checkbox"].on-off_BN +div{
  display: none;
}
.box_BN input[type="checkbox"].on-off_BN:checked +div{
  display: block;
}
/*  */
.box_BN label{
  display: block;
  cursor: pointer;
}
.box_PN input[type="checkbox"].on-off_PN,
.box_PN input[type="checkbox"].on-off_PN +div{
  display: none;
}
.box_PN input[type="checkbox"].on-off_PN:checked +div{
  display: block;
}
/*  */
.box_Number label{
  display: block;
  cursor: pointer;
}
.box_Number input[type="checkbox"].on-off_Number,
.box_Number input[type="checkbox"].on-off_Number +div{
  display: none;
}
.box_Number input[type="checkbox"].on-off_Number:checked +div{
  display: block;
}

/* 検証 */

.scroll-areaーyoko {
  width:100%;
  overflow-y: hidden;
  white-space: nowrap;
}
/* .scroll-areaーtate {
  border: 5px solid #aaa;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  overflow-x:hidden;
}
.simplebar-track.simplebar-vertical{
  display: none;
}
.simplebar-track.simplebar-horizontal{
  display:none;
} */
.scroll-areaーyoko ul {
  display: flex;
}
.scroll-areaーyoko ul li {
  list-style-type: none;
}

/* スクロールバーCSS */
.simplebar-scrollbar::before {
  /* background:#046B5E; */
  position: absolute;
  padding:6px;
  background-color: #046B5E;
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
      position: absolute;
      z-index: 100;
    }
.simplebar-track {
  position: relative;
  background: rgb(255, 255, 255);
  /* border: 1px solid #000000; */
  /* border-radius: 10px; */
  padding:8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.balloon {
  position: relative;
  display: inline-block;
  margin: 1.5em 0;
  padding:1em;
  min-width:12em;
  text-align:center;
  color: #046B5E;
  background: #ffffff;
  background: #ffffff;
  border: solid 2px #046B5E;
  box-sizing: border-box;
}

.balloon:before{
	content: "";
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -15px;
	border: 12px solid transparent;
	border-top: 12px solid #FFF;
	z-index: 2;
}

.balloon:after{
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -17px;
  border: 14px solid transparent;
  border-top: 14px solid #046B5E;
  z-index: 1;
}

/* スマートフォン用のスタイル */
@media (max-width: 768px) {
  .top_menu.col-md-5.col-12.px-0.photo {
    width: 95%;
    background-color: "#046B5E";
    text-decoration: "none";
    min-width: "350px"; 
  }
  .top_menu.col-md-5.col-12.px-0.cg {
    width: 95%;
    background-color: "#046B5E";
    text-decoration: "none";
    min-width: "350px"; 
  }
  .youtube iframe {
    width: 100%;
    height: 100%;
  }
  .col-12.bg-white.my-5.rounded{
    width: 95%;
    background-color: "#046B5E";
    text-decoration: "none";
    min-width: "350px"; 
  }
}

/* PC用のスタイル */
@media (min-width: 769px) {
  .top_menu.col-md-5.col-12.px-0 {
    width: 48%;
    background-color: "#046B5E";
    text-decoration: "none";
    min-width: "350px"; 
  }
  .youtube iframe {
    width: 75%;
    height: 75%;
  }
}

/* YouTube */
.youtube {
  width: 100%;
  aspect-ratio: 16 / 9;
}

/* 円グラフ */
.pie1_5 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background-image: radial-gradient(#ffffff 60%, transparent 61%), conic-gradient(#056b5e 0% 20%, #d9d9d9 20% 100%);
	border-radius: 50%;
}
.pie2_5 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background-image: radial-gradient(#ffffff 60%, transparent 61%), conic-gradient(#056b5e 0% 40%, #d9d9d9 40% 100%);
	border-radius: 50%;
}
.pie3_5 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background-image: radial-gradient(#ffffff 60%, transparent 61%), conic-gradient(#056b5e 0% 60%, #d9d9d9 60% 100%);
	border-radius: 50%;
}
.pie4_5 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background-image: radial-gradient(#ffffff 60%, transparent 61%), conic-gradient(#056b5e 0% 80%, #d9d9d9 80% 100%);
	border-radius: 50%;
}
.pie5_5 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background-image: radial-gradient(#ffffff 60%, transparent 61%), conic-gradient(#056b5e 0% 100%, #d9d9d9 100% 100%);
	border-radius: 50%;
}
